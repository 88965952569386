.event-page {
  .ant-card-head {
    @media only screen and (max-width: 575px) {
      padding-right: 15px !important;
      padding-left: 15px !important;
    }
  }

  &__title {
    text-wrap: balance;
  }
  &__organaizer {
    margin: 0 0 15px 0;
  }

  &__map {
    margin-top: 20px;
  }

  &__aside {
    position: sticky;
    top: 100px;

    .ant-card-head {
      font-size: 16px;
      color: black;
      font-weight: 400;
      // display: none;
    }

    &-action {
      display: flex;
      gap: 8px;
    }
  }
}

.main-page {
  background-color: var(--bg-main);
  &__content {
    margin-left: 0 !important;
    margin-right: 0 !important;
  }

  &__map {
    padding: 0 !important;

    @media only screen and (max-width: 575px) {
      left: 0px !important;
    }
  }

  .filter{
    padding: 10px 8px;
  }
}

.map-icon {
  bottom: 90px;
  z-index: 987;
  position: absolute;
  left: 0;
  right: 0;
  justify-content: center;
  margin: 0 auto;
  display: flex;

  @media only screen and (min-width: 575px) {
    display: none;
  }

  .ant-btn {
    background-color: var(--color-menu);
    border: none;
    color: var(--bg-white);
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 4px;
  }
}
