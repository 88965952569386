//   xs: '480px',
//   sm: '576px',
//   md: '768px',
//   lg: '992px',
//   xl: '1200px',
//   xxl: '1600px',
.__ym_wv_ign {
  visibility: hidden;
}
:root {
  --body: #f4f5f7;
  --contents-bg: #fcfcfc;
  --main-color: var(--bg-white);
  --accent: #ffffff;
  --color-menu: #404040;
  --color-menu-hover: #5840ff;
  --color-menu-active: #5840ff;
  --color-menu-bg-opacity: rgb(var(--color-primary-rgba), 0.1);
  --header-bg: white;
  --sidebar-bg: white;
  --main-content: var(--contents-bg);
  --header-bg: white;
  --logo-wrapper-bg: white;
  --color-add-listing-button-text: var(--color-white);
  --color-add-listing-button-text-hover: var(--color-white);
  --bgcolor-add-listing-button: #8231d3;
  --bgcolor-add-listing-button-hover: #0a0a0a;
  --color-banner_title: var(--color-white);
  --color-breadcrumb-link: #8c90a4;
  --color-breadcrumb-link-hover: #5840ff;
  --color-breadcrumb_separator: #8c90a4;
  --bgcolor-footer: #353b58;
  --color-footer-divider: #353b58;
  --color-footer-title: var(--color-white);
  --color-footer-link-hover: var(--color-white);
  --color-primary: #8231d3;
  --color-secondary: #5840ff;
  --color-grey: #8c8c8c;
  --color-success: #01b81a;
  --color-info: #00aaff;
  --color-warning: #fa8b0c;
  --color-danger: #ff0f0f;
  --color-dark: #0a0a0a;
  --color-infos: #00e4ec;
  --color-warnings: #ffbb00;
  --color-dangers: #ff0f0f;
  --color-purple: #a722f6;
  --bg-primary: #8231d3;
  --bg-secondary: #5840ff;
  --bg-success: #01b81a;
  --bg-info: #00aaff;
  --bg-warning: #fa8b0c;
  --bg-danger: #ff0f0f;
  --bg-dark: rgb(10, 10, 10);
  --bg-primary-hover: #6726a8;
  --bg-secondary-hover: #3520c8;
  --bg-success-hover: #0d811d;
  --bg-info-hover: #0787c7;
  --bg-warning-hover: #d9790a;
  --bg-danger-hover: #cb0000;
  --bg-dark-hover: #272525;
  --bg-gray-hover: #585858;
  --color-primary-rgba: 130, 49, 211;
  --color-secondary-rgba: 88, 64, 255;
  --color-success-rgba: 1, 184, 26;
  --color-info-rgba: 0, 170, 255;
  --color-warning-rgba: 250, 139, 12;
  --color-danger-rgba: 255, 15, 15;
  --color-dark-rgba: 10, 10, 10;
  --color-primary-rgba-shadow: rgba(var(--color-primary-rgba), 0.2);
  --color-white: #fff;
  --color-light: #747474;
  --color-lighten: #a0a0a0;
  --color-lighter: #f8f9fb;
  --color-lighten-x: #5d658c;
  --footer-dark: #353b58;
  --color-deep: #eff0f3;
  --color-normal: #f8f9fb;
  --color-lighters: #f4f5f7;
  --color-lighten-rgba: 173, 181, 217;
  --color-gray: #404040;
  --color-gray-x: #5a5f7d;
  --body-color: #666d92;
  --text-color: #666d92;
  --light-gray: #8c90a4;
  --light-gray-rgba: 173, 181, 217;
  --border-color: #f1f2f6;
  --border-light: #e3e6ef;
  --border-deep: #c6d0dc;
  --bg-main: #edeef0; // pllace used
  --bg-white: #ffffff; // pllace used
  --bg-normal: #f8f9fb;
  --bg-lighters: #f4f5f7;
  --bg-lighter: #f4f5f7;
  --bg-deep: #eff0f3;
  --bg-gray: #404040;
  --bg-light: #747474;
  --bg-light-rgba: 116, 116, 116;
  --bg-normal-rgba: 248, 249, 251;
  --bg-lighter-rgba: 244, 245, 247;
  --bg-deep-rgba: 239, 240, 243;
  --color-gray-rgba: 173, 181, 217;
  --color-dark-rgba: 39, 43, 65;
  --color-white-rgba: 255, 255, 255;
  --box-shadow-color-rgba: 146, 153, 184;
  --border-radius-xl: 10px;
  --border-radius-md: 6px;
  --border-radius-xs: 4px;
  --color-facebook: #2366b8;
  --color-twitter: #00abe4;
  --color-pinterest: #ef1b25;
  --color-gplus: #dc4e41;
  --color-linkedin: #007cbc;
  --color-tumblr: #35465c;
  --color-vk: #4a76a8;
  --color-youtube: #e32212;
  --color-instagram: #9c27b0;
  --color-ruby: #c2185b;
  --color-facebook-rgba: 35, 102, 184;
  --color-twitter-rgba: 0, 171, 228;
  --color-pinterest-rgba: 239, 27, 37;
  --color-gplus-rgba: 220, 78, 65;
  --color-linkedin-rgba: 0, 124, 188;
  --color-tumblr-rgba: 53, 70, 92;
  --color-vk-rgba: 74, 118, 168;
  --color-youtube-rgba: 227, 34, 18;
  --color-instagram-rgba: 156, 39, 176;
  --color-ruby-rgba: 194, 24, 91;
  --color-infos-rgba: 0, 228, 236;
  --color-warnings-rgba: 255, 187, 0;
  --color-dangers-rgba: 255, 15, 15;
  --color-purple-rgba: 167, 34, 246;
  --color-primary-rgba-10: rgba(var(--color-primary-rgba), 0.1);
  --color-secondary-rgba-10: rgba(var(--color-secondary-rgba), 0.1);
  --color-primary-rgba-50: rgba(var(--color-primary-rgba), 0.5);
  --color-secondary-rgba-50: rgba(var(--color-secondary-rgba), 0.5);
  --transition: all 0.3s ease;
  --gridLines_color: #e3e6ef;
  --scrollbarBG: var(--color-white);
  --thumbBG: #e3e6ef;
  --input-bg: var(--color-white);
  --input-focus-bg: var(--color-white);
  --tooltip-bg: var(--color-white);
  --tooltip-arrow-color: var(--color-white);
  --shadow2: #9299b80d;
  --shadow3: #9299b81a;
  --shadow4: #9299b833;
  --shadow5: #9299b826;
}

html {
  font-size: 16px;
  direction: ltr;
}

body {
  position: relative;
  min-height: 100vh;
  font-size: 16px;
  overflow-x: hidden;
  direction: ltr;
  background-color: var(--body);
}

a {
  transition: var(--transition);
}

ul {
  margin: 0;
  padding: 0;
  list-style: none;
}

.content-center {
  display: flex;
  align-items: center;
  justify-content: center;
}

.content-end {
  display: flex;
  align-items: center;
  justify-content: end;
}

.align-center {
  display: flex;
  align-items: center;
}

.d-flex {
  display: flex;
}

.align-center {
  display: flex;
  align-items: center;
}

.d-md-none {
  display: none;
}

.m-0 {
  margin: 0;
}

.color-primary {
  color: var(--color-primary);
}

.bg-primary {
  background-color: var(--color-primary);
  color: var(--bg-white);
}

.screen.page {
  background-color: var(--bg-main);
  // background-color: var(--main-content);
  font-size: 14px;
  > div {
    width: 100%;

    .ant-page-header {
      // background-color: var(--main-content) !important;
      // background-color: var(--bg-main) !important;
      background-color: #edeef0;
    }
  }

  .page__content {
    padding: 10px 20px 20px 20px;
    background-color: var(--bg-main);

    @media only screen and (max-width: 576px) {
      padding: 0 4px 8px 4px;
    }
  }
}

.mobile {
  display: none !important;
}

@media only screen and (max-width: 767px) {
  .mobile {
    display: block !important;
  }

  .desktop {
    display: none !important;
  }
}

// todo! refactor Temporary solution

.ant-anchor{
  margin-bottom: 70px;
}

//////////////
///
///
///
///
///
///
///
///
///

/* Utilitiy classes */

.spin {
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  background: rgba(255, 255, 255, 0.05);
}

.d-flex {
  display: flex;
}

.align-center-v {
  display: flex;
  align-items: center;
}

.justify-content-between {
  justify-content: space-between;
}

.justify-content-center {
  justify-content: center;
}

.align-items-center {
  align-items: center;
}

.line-height-0 {
  line-height: 0;
}

.text-right {
  text-align: right;
}

.text-left {
  text-align: left;
}

.text-center {
  text-align: center;
}

.medium-text {
  font-weight: 500;
}

/* spacing classes */

.m-0 {
  margin: 0 !important;
}

.mb-0 {
  margin-bottom: 0 !important;
}

.md-mb-0 {
  @media only screen and (max-width: 767px) {
    margin-bottom: 0px !important;
  }
}

.mb-20 {
  margin-bottom: 20px !important;
}

.mb-25 {
  margin-bottom: 25px !important;
}

.mb-30 {
  margin-bottom: 30px;
}

.pb-30 {
  padding-bottom: 30px;
}

.pl-0 {
  padding-left: 0 !important;
}

.mt-10 {
  margin-top: 10px !important;
}

.mt-20 {
  margin-top: 20px !important;
}

.mt-25 {
  margin-top: 25px;
}

.mt-30 {
  margin-top: 30px;
}

.pr-0 {
  padding-right: 0 !important;
}

.p-0 {
  padding: 0;
}

.p-10 {
  padding: 10px;
}

.p-20 {
  padding: 20px;
}
/* default card style */

.ant-p-0 {
  .ant-modal-body {
    padding: 0;
  }
}

.ant-card {
  box-shadow: 0 5px 20px rgba(173, 181, 217, 0.05);
}

.ant-card-body {
  padding: 25px !important;
}

.ant-card-head {
  padding-left: 25px !important;
  padding-right: 25px !important;
}

.ant-card-head-title span {
  display: inline-block;
  /* margin-left: 15px; */
  font-size: 11px;
  font-weight: 500;
  color: #868eae;
}

.ant-card-head .ant-card-extra {
  display: flex;
  align-items: center;
}

.ant-card-head .ant-card-extra:empty {
  display: none;
}

.ant-card-head .ant-card-extra a {
  color: #868eae;
}

.ant-card-extra .ant-dropdown-trigger {
  line-height: 0;
  order: 1;
  margin-left: 20px;
}

.pllace-unresizable {
  resize: none;
}

/* input styles */

.ant-form-item-label > label {
  font-weight: 500;
}

.ant-col.ant-form-item-control {
  display: contents;
}

.ant-picker-input > input::placeholder {
  color: #adb4d2 !important;
}

.ant-input-affix-wrapper,
.ant-input {
  border-radius: 6px;
  padding: 12px 20px;
}

.ant-input-affix-wrapper .ant-input-prefix {
  margin-right: 8px;
}

.ant-input-affix-wrapper-lg .ant-input-lg {
  font-size: 15px;
}

/* mail props dropdown */

.mail-props {
  padding: 10px 25px;
  border: 0 none;
  background: #fff;
  margin-bottom: 0;
  /* box-shadow: 0 5px 40px rgba(146, 153, 184, 0.25); */
}

.mail-props li {
  display: flex;
  margin-bottom: 12px;
}

.mail-props li span:first-child {
  margin-right: 50px;
  min-width: 40px;
}

.mail-props li span:last-child {
  color: #404040;
}

.mail-props li:last-child {
  margin-bottom: 0;
}

.mail-props li span {
  color: #9299b8;
}

/* Select Dropdwon */

.ant-select-dropdown {
  padding: 18px 0 !important;
  box-shadow: 0 5px 20px #9299b820 !important;
  border-radius: 0 0 6px 6px !important;
}

.ant-select-item {
  min-height: 20px !important;
  padding: 4px 12px !important;
}

.ant-select-item-group {
  color: #9299b8;
}

.ant-select-item.ant-select-item-option-grouped {
  padding-left: 25px !important;
}

.ant-select-dropdown .ant-select-item.ant-select-item-option-active {
  background: #8231d305;
}

.ant-select-dropdown
  .ant-select-item.ant-select-item-option-selected
  .ant-select-item-option-content {
  padding-left: 10px;
}

.ant-select-dropdown .ant-select-item.ant-select-item-option-selected {
  color: #8231d3;
  background: #8231d306;
}

.ant-select-dropdown
  .ant-select-item.ant-select-item-option-selected
  .ant-select-item-option-content {
  color: #8231d3;
  font-weight: 500;
}

.ant-select-dropdown .ant-select-item .ant-select-item-option-content {
  transition: 0.3s;
  color: #404040;
}

/* Basic Dropdwon */

.ant-dropdown {
  box-shadow: 0 5px 30px #9299b820 !important;
}

.ant-dropdown.wide-dropdwon {
  min-width: 140px !important;
}

.ant-dropdown.wide-dropdwon.kanbanCard-more {
  min-width: 220px !important;
  box-shadow: 0 17px 20px #9299b820;
}

.ant-dropdown.wide-dropdwon.kanbanCard-more a {
  padding: 10px 24px;
}

.ant-dropdown-menu {
  min-width: 200px;
  border-radius: 0 0 5px 5px;
  box-shadow: 0 0;
}

.ant-dropdown-menu li {
  color: #404040;
  padding: 5px 25px;
}

.ant-dropdown-menu li:hover {
  background-color: #8231d305;
}

.pllace-top-dropdown .pllace-top-dropdown__title {
  background: #f4f5f7;
  width: 100%;
  margin-bottom: 12px;
  border-radius: 6px;
  font-size: 14px;
  font-weight: 500;
  min-height: 50px;
  display: inline-flex;
  justify-content: center;
  align-items: center;
}

.pllace-top-dropdown {
  width: 340px;
}

.pllace-top-dropdown .pllace-top-dropdown__nav {
  height: auto;
}

.pllace-top-dropdown .pllace-top-dropdown__nav.notification-list {
  padding: 0 10px;
}

.pllace-top-dropdown .pllace-top-dropdown__nav li {
  width: 100%;
}

.pllace-top-dropdown .pllace-top-dropdown__nav li a {
  padding: 13px 10px;
  position: relative;
  width: 100%;
}

.pllace-top-dropdown .pllace-top-dropdown__nav li:last-child {
  margin: 0;
}

.pllace-top-dropdown a.btn-seeAll {
  position: relative;
  width: calc(100% + 30px);
  left: -15px;
  right: -15px;
  height: calc(100% + 15px);
  bottom: -15px;
  text-align: center;
  font-size: 13px;
  font-weight: 500;
  color: #8231d3;
  padding: 15px 0;
  border-radius: 0 0 6px 6px;
  justify-content: center;
  background: #fff;
  transition: 0.3s;
}

.pllace-top-dropdown a.btn-seeAll:before {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  box-shadow: 0 -15px 20px #9299b808;
  z-index: 1;
  content: "";
}

.pllace-top-dropdown .pllace-top-dropdown__nav li a.btn-seeAll:hover:after {
  box-shadow: 0 0;
}

.pllace-top-dropdown .pllace-top-dropdown__nav li a:hover {
  background: #fff;
}

.pllace-top-dropdown .pllace-top-dropdown__nav li a:hover:after {
  opacity: 1;
  visibility: visible;
}

.pllace-top-dropdown .pllace-top-dropdown__nav li a:hover figcaption h1 {
  color: #8231d3;
}

.pllace-top-dropdown .pllace-top-dropdown__nav li a:after {
  position: absolute;
  left: -15px;
  right: -15px;
  top: 0;
  /* width: calc(100% + 30px); */
  height: 100%;
  box-shadow: 0 15px 50px #9299b820;
  z-index: 1;
  content: "";
  opacity: 0;
  visibility: hidden;
  width: 100%;
}

.pllace-top-dropdown .pllace-top-dropdown__content {
  display: flex;
  align-items: flex-start;
  width: 100%;
  margin-bottom: 0;
}

.pllace-top-dropdown .pllace-top-dropdown__content .notification-icon {
  width: 39.2px;
  height: 32px;
  margin-right: 15px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.pllace-top-dropdown
  .pllace-top-dropdown__content
  .notification-icon.bg-primary {
  background: #8231d315;
  color: #8231d3;
}

.pllace-top-dropdown
  .pllace-top-dropdown__content
  .notification-icon.bg-secondary {
  background: #5840ff15;
  color: #5840ff;
}

.pllace-top-dropdown .pllace-top-dropdown__content .notification-icon svg {
  width: 18px;
  height: 18px;
}

.pllace-top-dropdown .pllace-top-dropdown__content .notification-content {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.pllace-top-dropdown .notification-text h1 {
  font-size: 14px;
  font-weight: 400;
  color: #404040;
  margin-bottom: 4px;
}

.pllace-top-dropdown .notification-text h1 span {
  color: #8231d3;
  font-weight: 500;
  padding-left: 0;
}

.pllace-top-dropdown .notification-text p {
  font-size: 12px;
  color: #adb4d2;
  margin-bottom: 0;
}

.pllace-top-dropdown .pllace-top-dropdown__content img {
  max-width: 40px;
}

.pllace-top-dropdown .pllace-top-dropdown__content figcaption {
  margin: -4px 15px 0;
}

.pllace-top-dropdown .pllace-top-dropdown__content figcaption .ant-badge-count {
  font-size: 8px;
  min-width: 16px;
  width: 16px;
  height: 16px;
  line-height: 16px;
  border-radius: 8px;
}

.pllace-top-dropdown
  .pllace-top-dropdown__content
  figcaption
  p.ant-scroll-number-only-unit.current {
  height: 15px;
}

.pllace-top-dropdown .pllace-top-dropdown__content figcaption h1 {
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 14px;
  font-weight: 500;
  margin-bottom: 2px;
}

.pllace-top-dropdown .pllace-top-dropdown__content figcaption h1 span {
  font-size: 12px;
  font-weight: 400;
}

.pllace-top-dropdown .pllace-top-dropdown__content figcaption p {
  display: flex;
  align-items: center;
  margin-bottom: 0;
}

.add-file-dropdown a {
  font-size: 14px;
  font-weight: 400;
}

.add-file-dropdown a svg {
  width: 14px;
}

.folder-dropdown {
  min-width: 200px !important;
}

/* Ant Dropdown */

.ant-dropdown {
  box-shadow: 0 5px 20px #9299b820;
  background: #fff;
  border-radius: 6px;
  border: 0 none;
}

.ant-dropdown a {
  display: flex;
  align-items: center;
  padding: 8px 24px;
  font-weight: 400;
  color: #404040;
}

.ant-dropdown a i,
.ant-dropdown a svg,
.ant-dropdown a img {
  margin-right: 8px;
  width: 14px;
  height: 14px;
}

.ant-dropdown div {
  box-shadow: 0 0;
  border-radius: 5px;
}

/* Button Styles */

.button-example .ant-btn {
  margin: 4px;
}

.button-example .ant-btn-group .ant-btn {
  margin: 0;
}

.ant-btn.ant-btn-white {
  color: #404040;
  border-color: #e3e6ef;
}

.ant-btn.ant-btn-white svg {
  transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
}

.ant-btn.ant-btn-white:hover {
  color: #8231d3;
  background-color: #fff;
  border-color: #8231d3;
}

.ant-btn.ant-btn-white:hover svg {
  color: #8231d3;
}

.ant-btn-white:focus {
  background-color: transparent !important;
}

.ant-btn.ant-btn-dashed {
  border-width: 1px !important;
  color: #090e30;
}

.ant-btn.ant-btn-dashed:hover {
  color: #8231d3;
  border: 1px dashed #8231d3;
}

.ant-btn-primary[disabled] {
  color: #fff;
  background: #8231d360;
}

.ant-btn-light[disabled] {
  background: #fff;
}

.ant-btn-round.ant-btn-sm {
  height: 38px;
}

.ant-btn-white[disabled] {
  background-color: transparent;
  opacity: 0.6;
}

.ant-btn-white[disabled]:hover {
  color: #404040 !important;
}

.ant-btn-primary[disabled]:hover {
  color: #fff !important;
  background: #8231d360 !important;
}

.btn-icon {
  padding: 0 13px;
}

.btn-inc,
.btn-dec {
  height: 38px;
  width: 38px;
  font-size: 20px;
  padding: 0 12px !important;
  border-radius: 10px !important;
  border: 0 none;
}

.btn-inc:hover,
.btn-dec:hover {
  background: #8231d310 !important;
  border: 0 none !important;
}

.btn-inc:hover i,
.btn-inc:hover svg,
.btn-dec:hover i,
.btn-dec:hover svg {
  color: #8231d3;
}

/* Badge Styles */

.ant-badge.badge-success .ant-badge-count {
  background: #01b81a;
}

.badge {
  font-size: 11px;
  font-weight: 500;
  padding: 0 6.5px;
  height: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 10px;
}

.badge .ant-badge-count {
  box-shadow: 0 0;
}

.badge.badge-primary {
  background: #8231d315;
  color: #8231d3;
}

/* color classes */

.color-primary {
  color: #8231d3;
}

.color-secondary {
  color: #5840ff;
}

.color-info {
  color: #00aaff;
}

.color-warning {
  color: #fa8b0c;
}

.color-success {
  color: #01b81a;
}

.color-danger {
  color: #ff0f0f;
}

.color-dark {
  color: #0a0a0a;
}

.color-error {
  color: #f5222d;
}

.color-gray {
  color: #404040;
}

/* pagination */

.ant-pagination
  .ant-select-single:not(.ant-select-customize-input)
  .ant-select-selector {
  height: 32px !important;
}

.ant-pagination-item-active {
  background-color: #8231d3 !important;
}

.ant-pagination-item-active a {
  color: #fff !important;
}

.ant-pagination .ant-pagination-options .ant-select-selection-item {
  font-size: 13px;
  line-height: 30px !important;
}

.ant-pagination .ant-pagination-options .ant-pagination-options-quick-jumper {
  height: 30px;
  line-height: 30px;
}

.ant-pagination
  .ant-pagination-options
  .ant-pagination-options-quick-jumper
  input {
  line-height: 30px;
  padding-top: 0;
  padding-bottom: 0;
}

.ant-pagination .ant-pagination-prev,
.ant-pagination .ant-pagination-next,
.ant-pagination .ant-pagination-jump-prev,
.ant-pagination .ant-pagination-jump-next,
.ant-pagination .ant-pagination-item,
.ant-pagination .ant-pagination-options .ant-select-selector {
  border: 1px solid #f1f2f6 !important;
  background-color: #fff;
}

.ant-pagination .ant-pagination-jump-prev .ant-pagination-item-ellipsis,
.ant-pagination .ant-pagination-jump-next .ant-pagination-item-ellipsis {
  color: #404040 !important;
  line-height: 2.6;
}

.ant-pagination .ant-pagination-jump-prev .ant-pagination-item-link,
.ant-pagination .ant-pagination-jump-next .ant-pagination-item-link {
  display: block;
}

.ant-pagination-prev,
.ant-pagination-next {
  line-height: 28px !important;
}

.ant-pagination-prev .ant-pagination-item-link,
.ant-pagination-next .ant-pagination-item-link {
  border: 0 none !important;
}

.ant-pagination .ant-pagination-item a {
  color: #404040;
}

.ant-pagination-jump-prev
  .ant-pagination-item-container
  .ant-pagination-item-ellipsis,
.ant-pagination-jump-next
  .ant-pagination-item-container
  .ant-pagination-item-ellipsis {
  font-family: "Jost", sans-serif !important;
}

.ant-pagination
  .ant-select-single
  .ant-select-selector
  .ant-select-selection-item {
  line-height: 30px !important;
  color: #404040;
}

.ant-pagination
  .ant-select-single:not(.ant-select-customize-input)
  .ant-select-selector
  .ant-select-selection-search-input {
  height: 100% !important;
}

.ant-pagination-item,
.ant-pagination-options,
.ant-pagination-prev,
.ant-pagination-jump-prev,
.ant-pagination-jump-next,
.ant-pagination-next {
  margin: 4px !important;
}

.ant-table-pagination {
  margin-top: 30px !important;
}

.ant-table-thead
  > tr
  > th:not(:last-child):not(.ant-table-selection-column):not(
    .ant-table-row-expand-icon-cell
  ):not([colspan])::before {
  display: none;
}

.ant-table table tr th.ant-table-selection-column,
.ant-table table tr td.ant-table-selection-column {
  padding: 0 16px;
}

/* leaflet Styles */

.leaflet-control a {
  display: none;
}

/* content Styles */

.empty-content {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
}

.ant-notification {
  z-index: 1010;
}

.ant-drawer-body {
  padding: 16px 0;
}
