.autor {
  display: flex;
  align-items: center;

  &__photo {
    background-color: transparent;
    border-radius: 25px;
    height: 50px;
    width: 50px;
    overflow: hidden;
    position: relative;

    &-img {
      height: 100%;
      width: 100%;
      mix-blend-mode: normal;
      position: relative;
      object-fit: cover;
    }
  }

  &__description {
    align-items: flex-start;
    background-color: transparent;
    display: flex;
    flex-direction: column;
    gap: 4px;
    padding: 0px 20px;
    width: auto;
    size: 16px;

    &-title {
      background-color: transparent;
      font-style: normal;
      font-weight: 400;
      letter-spacing: 0px;
      color: #8c8c8c;
      line-height: 18px;
      position: relative;
      text-align: left;
      white-space: nowrap;
      width: fit-content;
    }

    &-name {
      color: rgb(38, 38, 38);
      font-style: normal;
      font-weight: 400;
      letter-spacing: 0px;
      line-height: 16px;
      position: relative;
      text-align: left;
      white-space: nowrap;
      width: fit-content;
    }
  }
}

.sm.autor {
  .autor__photo {
    height: 30px;
    width: 30px;
  }
  .autor__description {
    padding: 0px 12px;

    &-title {
      line-height: 14px;
      font-size: 12px;
    }
  }
}
