.sidebar-content {
  box-sizing: border-box;
  padding: 20px 5px;

  .ant-select,
  .ant-picker {
    width: 100% !important;
    input {
      height: 35px;
    }
  }
}

.mobile-date-picker-component {
  .ant-picker-panels {
    display: flex;
    flex-direction: column;
  }
}

.mobile-location {
  .ant-form-item-control-input {
    width: 100%;
    padding-top: 8px !important;

    input {
      padding-top: 10px !important;
    }
  }

  .ant-select-selector {
    height: 45px !important;
    display: flex !important;
    justify-content: center !important;
    align-items: center !important;
  }
}


.filter-mobile{
  .slick-slider {
    display: block !important;
  }
}