.main {
  &-content {
    padding-top: 70px;
    min-height: 100vh;
    position: relative;
    // background-color: var(--main-content);

    .sidebar-wrapper {
      position: fixed;
      left: 0;
      top: 0;
      height: 100vh;

      .sidebar {
        position: static;
        height: 100%;
        overflow-y: auto;
        box-shadow: 0 0 30px rgba(var(--light-gray-rgba), 0.1);
        width: 280px;
        background: var(--color-white);
        padding: 0;
        transition: var(--transition);
        scrollbar-width: thin;
        transition: transform 300ms ease-in;
        padding-top: 68px;
      }
      .sidebar-collapsed {
        transform: translateX(-280px);
        transition: transform 300ms ease-out;
        display: block;
        z-index: 999;
      }
    }

    .sidebar-wrapper.opened {
      z-index: 500;
    }

    .contents {
      transition: var(--transition);
      background-color: var(--contents-bg);
    }

    .footer-wrapper {
      position: relative;
      // position: absolute;
      left: 0;
      bottom: 0;
      padding: 12px 30px;
      width: 100%;
      background: var(--color-white);
      transition: var(--transition);
      z-index: 501;
      box-shadow: rgba(146, 153, 184, 0.05) 0 -5px 10px;
    }
  }
}

@media only screen and (max-width: 768px) {
  .main {
    &-content {
      padding-top: 68px;
      overflow-x: hidden;

      .sidebar-wrapper {
        .sidebar {
          padding-top: 60px;
        }
      }
    }
  }
}

.sidebar-wrapper {
  z-index: 987;

  .ant-drawer-body {
    padding: 10px 0 0 0;
  }
}
