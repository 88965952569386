.participants {
  width: 100%;
  height: auto;
  position: relative;

  &__header {
    background-color: transparent;
    border-bottom-style: solid;
    border-bottom-width: 1px;
    border-color: #d9d9d9;
    border-left-style: none;
    border-right-style: none;
    border-top-style: none;
    height: 35px;
    min-width: 100%;
    position: relative;

    &-title {
      color: #262626;
      font-weight: 400;
      display: flex;
      gap: 4px;
      cursor: pointer;

    }

    &-under-line {
      background-color: rgb(130, 49, 211);
      height: 3px;
      mix-blend-mode: normal;
      position: absolute;
      top: 33px;
      width: 42px;
    }
  }

  &__content {
    margin-top: 24px;
    // display: flex;
    // justify-content: space-around;/
    // align-items: center;
    // gap: 40px;

    &-people {
      margin-bottom: 16px;
      display: flex;
      align-items: center;
      gap: 4px;
    }

    &-person {
      height: 45px;
      width: 45px;
      // border-radius: 50%;

      &:hover{
        cursor: pointer;
      }

      img {
        width: 100%;
        height: 100%;
        border-radius: 50%;
        object-fit: cover;
      }
    }

    .ant-badge {
      top: 4px;

      .ant-avatar-square {
        border-radius: 50%;
      }
    }
  }
}
