.event-card {
  margin-bottom: 16px;
  height: 100%;
  display: flex;
  flex-direction: column;
  // background-color: #f8f8f8;
  background-color: var(--bg-white);
  border-radius: 15px;
  box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.08), 0px 4px 12px rgba(0, 0, 0, 0.05);
  padding-top: 4px;

  &__photo {
    position: relative;
    top: -3px;
    width: 100%;
    // height: 100%;
    min-height: 275px;
    max-height: 275px;
    display: flex;
    justify-content: center;

    &-img {
      max-width: 100%;
      width: 100%;
      // height: 100%;
      border-radius: 10px 10px 0 0;
      object-fit: cover;
    }
  }

  &__content {
    margin-top: 8px;
    color: black;
    padding: 4px 8px 8px 8px;
    font-size: 14px;
    line-height: 1.3em;
    height: 100%;
    flex-direction: column;
    display: flex;
    justify-content: space-between;

    &_description {
      // height: 124px;
      &-start-date {
        color: rgb(116, 116, 116);
        font-size: 12px;

        span {
          margin-left: 8px;
        }
      }

      &-title {
        color: rgb(34, 34, 34);
        font-size: 18px;
        margin-top: 10px;
        height: 18px;
        display: -webkit-box;
        -webkit-line-clamp: 1; // количество строк
        -webkit-box-orient: vertical;
        // text-overflow: ellipsis;
        // white-space: nowrap;
        overflow: hidden;
      }
      &-text {
        color: rgb(113, 113, 113);
        padding: 0;
        margin-top: 4px;
        // height: 40px;
        display: -webkit-box;
        -webkit-line-clamp: 1; // количество строк
        -webkit-box-orient: vertical;
        overflow: hidden;
      }
    }
  }

  &__meta {
    display: flex;
    justify-content: start;
    color: #8c8c8c;
    font-size: 13px;
    margin: 8px 0 0 0;

    &--item {
      display: flex;
      text-align: center;
      margin-right: 16px;
      gap: 4px;
    }
  }
}
