.map-event {
  bottom: 75px;
  z-index: 987;
  position: absolute;
  left: 0;
  right: 0;
  margin: 0 auto;
  padding: 0;

  &__content {
    display: flex;
    justify-content: start;
    gap: 4px;

    &-text {
      padding: 15px 5px 5px 5px;
      display: flex;
      justify-content: space-between;
      flex-direction: column;
    }
  }

  .ant-card-body {
    padding: 0 !important;
  }

  img {
    object-fit: cover;
  }

  .close {
    position: absolute;
    top: 5px;
    right: 5px;
    cursor: pointer;
  }
}

.map-event.isDesctope {
  bottom: 15px;
}
