@import "./media-queries";

.chat-page {
  padding: 20px;

  @include xs {
    padding: 0;
  }
  // background-color: green;
  min-height: max-content;
  // height: calc(100vh - 60px);
  box-sizing: border-box;

  .chat-sidebar {
    padding-top: 20px;
    min-height: 672px;
    width: 100%;
    height: calc(100vh - 113px);
    background: var(--bg-white);
    display: flex;
    flex-direction: column;
    flex: 1;
    box-shadow: 0 5px 20px rgba(146, 153, 184, 0);
    box-sizing: border-box;
    overflow-y: hidden;

    .search-tab {
      padding: 0 25px;
      height: 55px;
      border-bottom: 1px solid #eff0f3;

      ul {
        display: flex;
        justify-content: space-around;

        li {
          font-size: 14px;
          line-height: 1.5;
          font-weight: 400;

          a {
            color: #747474;
            display: flex;
            gap: 4px;
            padding: 15px 0;
          }

          a.active {
            color: var(--color-primary);
            border-bottom: 2px solid var(--color-primary);
          }
        }
      }
    }

    .search-body {
      height: 100%;
      position: relative;

      //User
      .user-list-item {
        padding: 19px 0;
        transition: all 200ms ease-in-out;
        transform: scale(1);

        &::after {
          position: absolute;
          left: 0;
          top: 0;
          width: 100%;
          height: 100%;
        }

        &:hover {
          &:hover {
            box-shadow: 0 15px 50px var(--shadow4);
          }
        }

        &__wrapper {
          padding: 0 25px;
          padding-right: 20px;
          display: flex;
          cursor: pointer;
          position: relative;
          align-items: center;

          @include xs {
            padding: 0 15px;
          }

          .avatar {
            margin-right: 15px;
            display: inline-block;
            position: relative;
            margin-bottom: 0;

            img {
              width: 46px;
              height: 46px;
              object-fit: cover;
              border-radius: 50%;
            }

            .badge-direction-bottom {
              position: absolute;
              display: inline-flex;
              align-items: center;
              justify-content: center;
              background: var(--color-white);
              border-radius: 50%;
              padding: 0;
              bottom: 0;
              right: 0;

              .avatar-online {
                background-color: var(--color-success);
                border: 2px solid var(--color-white);
                height: 12px;
                width: 12px;
                border-radius: 5px;
              }

              .avatar-offline {
                background-color: var(--color-danger);
                border: 2px solid var(--color-white);
                height: 12px;
                width: 12px;
                border-radius: 5px;
              }
            }
          }

          .users-list-body {
            flex: 1;
            position: relative;
            min-width: 0;
            display: flex;

            @include xs {
              display: grid;
            }

            .users-list-body-title {
              min-width: 0;
              display: flex;
              flex-direction: column;
              flex: 1;
              justify-content: center;

              h6 {
                font-size: 14px;
                line-height: calc(20px / 14px);
                margin-bottom: 6px;

                @include sm {
                  margin-bottom: 0px;
                }
              }
              .text-limit {
                overflow-x: hidden;
                display: -webkit-box;
                -webkit-line-clamp: 1;
                p {
                  font-size: 14px;
                  line-height: calc(22px / 14px);
                  font-weight: 400;
                  color: var(--color-light);

                  @include xl {
                    white-space: nowrap;
                  }
                }
              }
            }

            .last-chat-time {
              padding-left: 10px;
              position: relative;
              // text-transform: uppercase;
              display: flex;
              flex-direction: column;
              justify-content: center;
              align-items: flex-end;

              @include sm {
                display: flex;
                padding-left: 0;
                flex-direction: row;
                justify-content: space-between;
              }

              small {
                color: var(--color-light);
                font-size: 12px;
                line-height: calc(22px / 12px);
                font-weight: 400;
              }

              .ant-badge {
                border-radius: 10px;
                padding: 0 6px;
                margin: 0;
                z-index: auto;
                width: min-content;
                max-width: 40px;
                height: 20px;
                font-weight: 400;
                line-height: 20px;
              }
            }
          }
        }
      }
    }
  }
}

.drawer-chat {
  height: 100%;
  div {
    display: block !important;
  }
}

.drawer-chat-wrapper {
  .ant-drawer-body {
    padding: 0;
  }

  .ant-card-body {
    padding: 0 !important;
    .atbd-chatbox {
      height: 77vh;
    }
  }

  @media only screen and (max-width: 500px) {
    .ant-card .ant-card-body {
      padding: 10px 0 !important;
    }
    // .ant-card-body {
    //   padding: 0px !important;
    //  }
  }
}

.drawer-chat-wrapper.single {
  .ant-card-head {
    max-height: 80px;
  }
}

.drawer-chat-wrapper.group {
  .ant-card-extra {
    display: none !important;
  }
}
