.footer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 14px;
  font-weight: 500;
  position: relative;
  z-index: 501;

  a {
    color: var(--color-menu);
  }

  .footer-copyright {
    &__link {
      margin-left: 4px;
      color: var(--color-primary);
    }
  }
}

@media only screen and (max-width: 576px) {
  .footer {
    flex-direction: column;
    gap: 2px;
    font-size: 12px;
  }
}
