.header-date-picker {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 6px 10px 6px 30px;
  border: 1px solid #dddddd;
  box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.08), 0px 4px 12px rgba(0, 0, 0, 0.05);
  border-radius: 40px;
  margin-left: auto;
  margin-right: auto;

  .ant-form-item {
    margin: 0;
  }

  .ant-select-single:not(.ant-select-customize-input) .ant-select-selector {
    height: auto !important;
  }

  .ant-select {
    display: flex;
    align-items: center;
    justify-content: center;

    min-width: 100px;
    &-selector {
      border: 0 !important;
    }
  }

  .ant-picker {
    border: 0;
    min-width: 200px;
    margin-left: 4px;

    &-input {
      width: 80px;
    }

    &-suffix {
      display: none;
    }

    &-clear {
      right: -11px;
    }
  }

  &__button > button {
    height: 40px;
    width: 40px;
    background-color: rgb(130, 49, 211);
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 40px;
    color: var(--bg-white);
    margin-left: 16px;
    font-size: 16px;
  }
}

.ant-select-dropdown > div {
  margin-top: 10px;
}
