.slider-container {
    @media only screen and (max-width: 575px){
        padding: 0;
    }

    color: #717171;
    padding: 12px 0 0 0;
    border-bottom: 0.5px solid rgb(0 0 0/0.3);

    .slick-slider{
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .reset {
        display: flex !important;
        flex-direction: row;
        justify-content: center;
        align-items: center;

        .slider-category {
            &::after {
                display: none;
            }
        }

        &-divider{
            margin-left: 12px;
            height: 2.9em;
        }
    }

    .slider-category {
        display: flex !important;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        cursor: pointer;        

        &__text {
            white-space: nowrap;
            font-size: 12px;
            margin-top: 4px;
            font-weight: 300;
        }

        &:hover {
            svg, span {
                transform: scale(1.1);
            }
        }

        @media only screen and (max-width: 575px){
            svg {
                height: 18px;
                width: 18px;
            }

            &__text {
                font-size: 10px;
            }
        }
        
    }

    .slider-category {
        &::after {
            content: "";
            display: block;
            width: 100%;
            height: 2px;
            background-color: none;
            margin-top: 8px;
        }

        &.active{
            &::after {
                background-color: var(--bgcolor-add-listing-button);
            }
        }
    }
}

.arrow-btn {
    height: 30px;
    width: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 5px;
    border-radius: 50%;
    border: 0.5px solid rgb(0 0 0/0.3);
    cursor: pointer;
    position: relative;
    top: -6px;

    &:hover {
        transform: scale(1.04);
        box-shadow: 0 6px 16px rgba(0,0,0,0.12);
    }

    @media only screen and (max-width: 575px){
        display: none;
    }
}