.meta {
  display: flex;
  text-align: center;
  margin-right: 16px;
  gap: 4px;

  span {
    margin: 0;
  }

  &.likes {
    cursor: pointer;

    .icon .filled svg {
      fill: #8231d3;
    }
  }
}

.meta__container {
  display: flex;
  justify-content: start;
  color: #8c8c8c;
  font-size: 13px;
  margin: 8px 0 0 0;
}
