.header {
  &__dropdown-custom-notification,
  &__dropdown-custom-messages {
    .ant-popover-inner-content {
      width: 100%;
      padding: 0;
      padding-top: 4px;
    }

    .dropdown-wrapper {
      &__title {
        color: var(--color-dark);
        margin: 10px;
        border-radius: 6px;
        background: var(--bg-lighter);
        padding: 15px;
        font-size: 14px;
        text-align: center;

        .ant-badge {
          margin-left: 8px;
        }
      }

      &__more {
        padding: 15px;
        text-align: center;
        display: block;
        box-shadow: 0 -15px 20px rgba(var(--light-gray-rgba), 0.08);
        color: var(--color-lighten);
        font-size: 13px;
        font-weight: 500;

        &:hover {
          color: var(--color-primary);
        }
      }

      &__content {
        display: flex;
        align-items: start;
        padding: 15px 25px;

        &:hover {
          box-shadow: 0 15px 50px rgba(var(--light-gray-rgba), 0.2);
        }

        .nav-message {
          &__type img {
            width: 40px;
            height: 40px;
            object-fit: cover;
          }

          &__details {
            margin-left: 8px;
            min-width: 270px;
            width: 100%;
            font-size: 14px;
            line-height: 20px;
            font-weight: 400;
            color: var(--color-grey);

            &-top,
            &-bottom {
              display: flex;
              flex-direction: row;
              justify-content: space-between;
              align-items: center;

              .time {
                color: var(--color-success);
                font-size: 12px;
              }
              .name {
                color: var(--color-dark);
                font-weight: 600;
              }
            }
          }
        }

        .nav-notification {
          &__type {
            width: 30px;
            height: 30px;
            display: flex;
            align-items: center;
            justify-content: center;
            border-radius: 50%;
            margin-right: 15px;
            background: rgba(130, 49, 211, 0.082);
            color: var(--color-primary);
          }

          &__details {
            display: flex;
            justify-content: center;

            &-single {
              @media only screen and (max-width: 576px) {
                max-width: 100%;
              }
              max-width: 220px;
              font-size: 14px;
              font-weight: 400;
              color: var(--color-grey);
              margin-bottom: 4px;

              &-text {
                span {
                  color: var(--color-secondary);
                  font-weight: 600;
                }
              }

              a {
                color: var(--color-primary);
              }

              .time {
                font-size: 12px;
                color: var(--color-light);
              }
            }
          }
        }
      }
    }
  }

  &__dropdown-custom-language {
    z-index: 989;
    // top: 73px !important;

    .ant-dropdown-menu-title-content {
      a {
        padding: 2px 8px;
        display: flex;
        align-items: center;

        img {
          height: 14px;
          width: 14px;
        }

        span {
          margin-left: 8px;
        }
      }
    }
  }

  &__dropdown-custom-auth,
  &__dropdown-custom-no-auth {
    // top: 60px !important;

    .ant-popover-inner-content {
      padding: 0;
      width: 100%;
    }

    .dropdown-wrapper {
      width: 100%;
      background: var(--color-white);
      box-shadow: 0 5px 30px rgba(var(--light-gray-rgba), 0.15);

      ul {
        list-style: none;
        margin: 0;
        padding: 0 0 15px;
        font-size: 14px;

        a {
          align-items: center;
          display: flex;
          gap: 12px;
          padding: 9px 25px 9px;
          color: var(--color-light);
          transition: var(--transition);

          &:hover {
            background: rgba(var(--color-primary-rgba), 0.05);
            padding-left: 35px;
            color: var(--color-primary);
          }
        }
      }
    }
  }

  &__dropdown-custom-auth {
    max-width: 270px !important;
    width: 100%;

    .user-dropdown {
      padding-top: 1px;

      .nav-author {
        padding-top: 4px;

        &__info {
          background: var(--bg-lighter);
          border-radius: 8px;
          margin: 10px;
          display: flex;
          align-items: center;
          padding: 20px 25px;

          .author-img {
            margin-right: 15px;

            img {
              max-width: 40px;
            }
          }
        }

        &__options {
          .nav-author__signout {
            font-size: 13px;
            background: var(--bg-lighter);
            padding: 18px 15px;
            color: #868eae;
            display: flex;
            align-items: center;
            justify-content: center;
            text-align: center;
            font-weight: 500;
            gap: 8px;

            &:hover {
              color: var(--color-primary);
            }
          }
        }
      }
    }
  }

  &__dropdown-custom-no-auth {
    max-width: 180px !important;
    width: 100%;

    .nav-author__options {
      padding-top: 16px;
    }
  }

  @media only screen and (max-width: 768px) {
    // &__dropdown-custom-auth,
    // &__dropdown-custom-no-auth {
    //     top: 90px !important;
    // }

    // &__dropdown-custom-language {
    //     top: 100px !important;
    // }
  }

  @media only screen and (max-width: 576px) {
    &__dropdown-custom-notification,
    &__dropdown-custom-messages {
      width: 100%;
    }
  }
}

.nav-message .bell,
.nav-notification .bell {
  -webkit-animation: ring 2s ease; // infinite;
  animation: ring 2s ease;
  animation-delay: 1s; /* Добавьте эту строку */
}

/* BELL */
@keyframes ring {
  0% {
    transform: rotate(-15deg);
  }
  2% {
    transform: rotate(15deg);
  }
  4% {
    transform: rotate(-18deg);
  }
  6% {
    transform: rotate(18deg);
  }
  8% {
    transform: rotate(-22deg);
  }
  10% {
    transform: rotate(22deg);
  }
  12% {
    transform: rotate(-18deg);
  }
  14% {
    transform: rotate(18deg);
  }
  16% {
    transform: rotate(-12deg);
  }
  18% {
    transform: rotate(12deg);
  }
  20% {
    transform: rotate(0deg);
  }
}
