.header-top,
.mobile-author-actions {
  position: fixed;
  width: 100%;
  left: 0;
  top: 0;
  z-index: 988;
  background: var(--main-color);
  box-shadow: 0 5px 20px rgba(var(--color-lighten-rgba), 0.05);

  .navbar {
    padding: 0 0;
    padding-right: 30px;
    background-color: var(--header-bg);
    flex-wrap: nowrap;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: space-between;

    .navbar-left {
      display: flex;
      align-items: center;
      justify-content: center;

      .logo-area {
        display: flex;
        padding-left: 30px;
        align-items: center;
        background-color: var(--logo-wrapper-bg);
        justify-content: space-between;

        .svg {
          position: relative;
          top: -3px;
        }

        .navbar-brand {
          img,
          svg {
            max-width: 160px;
            min-width: 160px;
          }
        }

        .sidebar-toggle {
          display: none;
          margin-right: 8px;

          img {
            width: 18px;
            height: 18px;
          }

          img,
          svg {
            width: 18px;
            pointer-events: none;
            color: black;
          }

          .animated-icon {
            transition: transform 0.3s ease;
            display: inline-block;
          }

          .hide-icon {
            transform: rotate(90deg);
            opacity: 0;
            position: absolute;
            pointer-events: none;
          }

          .show-icon {
            transform: rotate(0deg);
            opacity: 1;
          }
        }
      }
    }

    .navbar-right {
      &__menu {
        list-style: none;
        display: flex;
        align-items: center;
        padding-left: 17px;
        padding-top: 5px;

        li {
          padding: 20px 12.5px;
        }

        li.nav-author {
          padding-right: 0;
        }

        .nav-notification {
          ul {
            list-style: none;
            padding: 0;
            margin: 0;
            max-height: 320px;
            overflow: hidden auto;
            scrollbar-width: thin;
            scrollbar-color: var(--thumbBG) var(--scrollbarBG);
          }

          ul::-webkit-scrollbar {
            width: 11px;
          }

          ul::-webkit-scrollbar-track {
            background: var(--scrollbarBG);
          }

          ul::-webkit-scrollbar-thumb {
            background-color: var(--thumbBG);
            border-radius: 6px;
            border: 3px solid var(--scrollbarBG);
          }
        }

        .nav-flag-select img {
          width: 20px;
          border-radius: 50%;
        }

        .nav-author {
          a {
            color: var(--color-primary-rgba);

            :visited {
              color: transparent;
            }
          }
          img {
            width: 32px;
            height: 32px;
            object-fit: cover;
          }
        }
      }

      &__mobileAction {
        padding: 15px 0;
        display: none;

        img,
        svg {
          position: relative;
          // top: -3px;
          width: 20px;
          color: var(--color-light);
        }
      }
    }
  }
}

@media only screen and (max-width: 992px) {
  .header-top {
    .navbar {
      .navbar-left {
        .logo-area {
          .sidebar-toggle {
            display: block;
          }
        }
      }

      .navbar-middle {
        display: none;
      }
    }
  }
}

@media only screen and (max-width: 768px) {
  .header-top {
    .navbar {
      padding-right: 0;

      .navbar-left {
        .logo-area {
          padding-left: 15px;
        }
      }

      .navbar-right {
        &__menu {
          display: none;
        }

        &__mobileAction {
          display: block;
        }
      }
    }
  }
}

.btn-author-action {
  .animated-icon {
    transition: transform 0.3s ease;
    display: inline-block;
    position: absolute !important;
    right: 10px;
    top: 2px;
  }

  .hide-icon {
    transform: rotateX(90deg);
    opacity: 0;
    position: absolute;
    pointer-events: none;
  }

  .show-icon {
    transform: rotateX(0deg);
    opacity: 1;
  }
}

.mobile-author-actions {
  position: fixed;
  width: 100%;
  height: 50px;
  top: 60px;
  z-index: 999;
  padding: 0 5px;
  display: flex;
  align-items: center;
  justify-content: end;
  opacity: 0;
  box-shadow: 0 2px 30px rgba(146, 153, 184, 0.063);
  background-color: var(--color-white);
  display: none;

  .navbar {
    padding-right: 15px;
    justify-content: flex-end;

    .navbar-right {
      &__menu li {
        padding: 0px 12.5px;
      }
    }
  }
}

.mobile-author-actions.show {
  opacity: 1;
  display: block;
  transition: 0.25s;
}

.mobile-author-drawer {
  z-index: 987;
  top: 47px;
  .ant-drawer-header {
    display: none;
  }
  .ant-drawer-mask {
    opacity: 0;
  }
  .ant-drawer-body {
    padding: 0;
  }

  .navbar {
    padding: 0 0;
    padding-right: 10px;
    background-color: var(--header-bg);
    flex-wrap: nowrap;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: end;

    .navbar-right {
      &__menu {
        list-style: none;
        display: flex;
        align-items: center;
        padding-left: 17px;
        padding-top: 5px;

        li {
          padding: 20px 12.5px;
        }

        li.nav-author {
          padding-right: 0;
        }

        .nav-notification {
          ul {
            list-style: none;
            padding: 0;
            margin: 0;
            max-height: 320px;
            overflow: hidden auto;
            scrollbar-width: thin;
            scrollbar-color: var(--thumbBG) var(--scrollbarBG);
          }

          ul::-webkit-scrollbar {
            width: 11px;
          }

          ul::-webkit-scrollbar-track {
            background: var(--scrollbarBG);
          }

          ul::-webkit-scrollbar-thumb {
            background-color: var(--thumbBG);
            border-radius: 6px;
            border: 3px solid var(--scrollbarBG);
          }
        }

        .nav-flag-select img {
          width: 20px;
          border-radius: 50%;
        }

        .nav-author {
          padding-right: 5px !important;
          a {
            color: var(--color-primary-rgba);

            :visited {
              color: transparent;
            }
          }
          img {
            width: 32px;
            height: 32px;
            object-fit: cover;
          }
        }
      }

      &__mobileAction {
        padding: 15px 0;
        display: none;

        img,
        svg {
          position: relative;
          top: -3px;
          width: 20px;
          color: var(--color-light);
        }
      }
    }
  }
}
